@use '../../abstracts/' as *;

.dashboard {
  &__info {
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
  }

  .dash-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-primary;
    padding: 1rem;
    text-align: center;

    & .metric {
      font-size: 1rem;
      font-weight: 700;
    }

    & .number {
      font-size: 4rem;
      line-height: 1;
      font-weight: 700;
    }
  }
}
