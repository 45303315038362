// HERO SECTION
// BASE
@use '../../../components' as *;
@use '../../../abstracts' as *;

.hero-education {
  & .content {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 6rem;
    align-items: center;

    &-image {
      grid-column: 1 / 2;
    }

    &-text {
      color: $color-accent;
      grid-column: 2 / -1;
      font-size: 2.4rem;
      line-height: 1.4;
      text-align: left;
    }
  }

  &__def {
    margin-top: 4rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 2.4rem;
      text-align: center;
    }

    &-text {
      font-size: 1.5rem;
      margin-top: 1rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 4rem;

      &--left {
        grid-column: 1 / 2;
      }

      &--right {
        grid-column: 2 / -1;
      }
    }

    a {
      width: fit-content;
      align-self: center;
      margin-top: 2rem;
    }
  }

  &__alert {
    background-color: $color-primary-light;
    color: #000000;
    text-align: center;
    padding: 1rem;
    font-size: 1.2rem;
    margin-bottom: 4rem;
  }
}

/* Extra large: Desde 993px hasta 1200px */
@media screen and (max-width: 1200px) {
  .hero-education {
    & .content {
      margin-top: 3.5rem;
    }
    &__header {
      column-gap: 4rem;
    }

    &__def {
      &-title {
        font-size: 2rem;
      }

      &-text {
        column-gap: 3rem;
        font-size: 1.4rem;
      }
    }
  }
}

/* Large: Desde 769px hasta 992px */
@media screen and (max-width: 992px) {
  .hero-education {
    & .content {
      margin-top: 3.5rem;
    }
    &__header {
      column-gap: 2rem;

      &-text {
        font-size: 1.6rem;
      }
    }

    &__def {
      &-title {
        font-size: 2rem;
      }

      &-text {
        column-gap: 3rem;
        font-size: 1.1rem;
      }
    }

    &__alert {
      font-size: 1rem;
    }
  }
}

/* Medium: Desde 577px hasta 768px */
@media screen and (max-width: 768px) {
  .hero-education {
    &__header {
      column-gap: 1rem;
      &-text {
        font-size: 1.4rem;
      }
    }

    &__def {
      margin-top: 3rem;

      &-title {
        font-size: 1.6rem;
      }

      &-text {
        column-gap: 2rem;
        font-size: 1.1rem;
      }
    }

    &__alert {
      font-size: 0.9rem;
    }
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .hero-education {
    & .content {
      margin-top: 1.5rem;
      padding: 0;
    }

    &__header {
      column-gap: 1rem;
      grid-template-columns: 1fr;

      &-image {
        grid-column: 1 / -1;
      }

      &-text {
        grid-column: 1 / -1;
        font-size: 1.2rem;
        margin-top: 1rem;
      }
    }

    &__def {
      margin-top: 2rem;
      margin-bottom: 2rem;

      &-title {
        font-size: 1.4rem;
        line-height: 1.3;
      }

      &-text {
        grid-template-columns: 1fr;
        font-size: 1.1rem;

        &--left,
        &--right {
          grid-column: 1 / -1;
        }
      }
    }
  }
}
