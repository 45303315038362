@use '../abstracts' as *;

.product {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  align-items: stretch;
  border-radius: initial;
  background-color: white;
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.2);
  padding: 1.4rem;

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__image {
    
    
  }

  &__name {
    font-size: 1.1rem;
    font-weight: 700;
    color: $color-primary;
    text-align: center;
    margin-bottom: 0.1rem;
  }

  &__description {
    font-size: 1rem;
    text-align: center;
    color: $color-dark;
    margin-bottom: 0.5rem;
  }

  &__price {
    font-size: 2rem;
    text-align: center;
    color: $color-accent;
    font-weight: 800;
    line-height: 1;
  }

  &__iva{
    text-align: center;
    font-size: 0.8rem;
  }

  &__button  {
    align-self: center;
    margin-top: 1rem;
  }
}

/* Medium: Desde 577px hasta 768px */
@media screen and (max-width: 768px) {
  .product {
    grid-template-columns: 1fr;
    padding: 1.4rem 0;

    &__left { 
      justify-content: center;
    }

    &__name {
      font-size: 1rem;
    }
    &__price {
      font-size: 1.8rem;
    }
  }
}