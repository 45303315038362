.invoice-print {
  & .invoice-button {
    display: flex;
    justify-content: center;

    & button {
      margin-bottom: 4rem;
      width: fit-content;
    }
  }
}
