@use '../../../../abstracts' as *;

.subscription {
  padding: 2rem 0 2rem 0;
  background-color: $color-primary;

  &__row {
    display: flex;
    column-gap: 2rem;
    align-items: center;
  }

  &__data {
    display: flex;
    align-items: center;
  }

  // Formulario
  &__form {
    display: grid;
    grid-template-columns: 1fr max-content;
    row-gap: 0.6rem;

    & .input {
      border-radius: 0;
      border: 1px solid $color-accent;
      background-color: transparent;
      padding: 8px;

      &:focus {
        box-shadow: none;
      }
    }

    .button {
      width: auto;
      border-radius: 0;
      background-color: $color-accent;
      border-color: $color-accent;
    }

    .checkbox {
      & input {
        border-radius: 0;
        background-color: transparent;
        border-color: $color-accent;

        &:checked {
          background-color: $color-accent;
        }
      }

      & label {
        font-family: var(--body-font-family);
        @include font-size($input-alt-font-sizes);
        color: $color-dark;
        line-height: 1.4;
      }
    }
  }

  & .alert {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
}

/* Medium: Desde 577px hasta 768px */
@media screen and (max-width: 768px) {
  .subscription {
    &__row {
      flex-direction: column;
      margin: 0 3rem;
    }

    &__form {
      margin-top: 1rem;
      & .input {
        padding: 9px;
      }
    }
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .subscription {
    &__row {
      flex-direction: column;
      margin: 0;
    }
  }
}
