// HERO SECTION
// BASE
@use '../../../../components' as *;
@use '../../../../abstracts' as *;

.hero-home {
  background-image: url(../../../../../assets/images/home/home-v1.png);
  background-position: top;
  align-self: center;
  background-repeat: no-repeat;
  width: 100%;

  & .content {
    display: grid;
    grid-template-columns: min-content 0.5fr 1fr;
    column-gap: 2rem;
    height: 100vh;
  }

  & .data {
    grid-column: 3;
    align-self: flex-end;
    margin-bottom: 2rem;
    text-align: right;

    &__title {
      font-size: 3.5rem;
      line-height: 1.1;
      color: #fbfbfb;
      font-weight: 900;
      text-shadow: 4px 5px 7px #000000;

      &--italic {
        font-family: var(--prime-font-family);
        font-size: 4rem;
      }
    }
  }

  & .rrss {
    grid-column: 1;
    align-self: center;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    font-size: 1.2rem;
    color: $color-light-100;

    & a {
      &:hover {
        color: $color-primary;
      }

      & i {
        font-size: 1.6rem;
      }
    }
  }

  & .space {
    grid-column: 2;
  }
}

/* Extra large: Desde 993px hasta 1200px */
@media screen and (max-width: 1200px) {
  .hero-home {
    & .data {
      &__title {
        font-size: 2.6rem;
        &--italic {
          font-size: 3.2rem;
        }
      }
    }
  }
}

/* Large: Desde 769px hasta 992px */
@media screen and (max-width: 992px) {
  .hero-home {
    background-image: url(../../../../../assets/images/home/home-v2.png);
    & .data {
      align-self: flex-end;
      padding-bottom: 1rem;
      &__title {
        font-size: 2.3rem;
        line-height: 1.2;
        &--italic {
          font-size: 2.7rem;
        }
      }
    }
  }
}

/* Medium: Desde 577px hasta 768px */
@media screen and (max-width: 768px) {
  .hero-home {
    background-image: url(../../../../../assets/images/home/home-v2.png);
    background-position: top left;
    & .content {
      grid-template-columns: min-content min-content 1fr;
    }
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .hero-home {
    & .content {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr min-content;
      column-gap: 0rem;
      height: 80vh;
      padding-bottom: 1rem;
    }

    & .data {
      grid-column: 1;
      grid-row: 1;
      text-align: center;
      margin-bottom: 0;
      padding-bottom: 0;

      &__title {
        font-size: 1.5rem;
        line-height: 1.3;
        &--italic {
          font-size: 1.8rem;
          line-height: 1;
        }
      }
    }

    & .rrss {
      grid-column: 1;
      grid-row: 2;
      flex-direction: row;
      justify-content: center;
      column-gap: 2rem;
      font-size: 1rem;
      margin-top: 1rem;
    }
  }
}

/* Extra-Small: Desde 0px hasta 350px */
@media screen and (max-width: 350px) {
}
