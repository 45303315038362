@use '../../../abstracts' as *;
.cart {
  margin-top: 4rem;
  margin-bottom: 4rem;

  & .title {
    margin-bottom: 2rem;
  }

  & .contentCart {
    display: flex;
    flex-direction: column;

    & .emptycart {
      margin-bottom: 2rem;
    }

    & .continue-order-btn {
      padding: 1rem;
      width: max-content;
      font-weight: 700;
      align-self: start;
    }
  }

  & .cartbox {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: end;

    & .cartItems {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }

    & .cartItem {
      display: grid;
      grid-template-columns: 75px repeat(4, 1fr);
      align-items: center;
      justify-items: center;
      background-color: #ffffff;
      border: 1px solid $color-primary;
      padding: 1rem;

      &__btn {
        width: 50px;
        padding: 1rem;
      }

      & img {
        width: 70px;
      }

      &__name {
        font-size: 1.1rem;
        text-align: center;
      }

      &__type {
        font-size: 1rem;
        margin-top: 0;
        text-align: center;
      }

      &__btnCounter {
        display: flex;
        flex-direction: row;

        &--btn {
          width: 50px;
          padding: 1rem;
          font-size: 1.8rem;
          line-height: 0;
        }

        &--number {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem 2rem;
          border: 1px solid $color-light-200;
          font-size: 1rem;
        }
      }

      &__subtotal {
        font-size: 1.2rem;
        font-weight: 600;
        justify-self: flex-end;
      }
    }

    & .totalcart {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid $color-primary;

      &__title {
        font-size: 1.5rem;
        color: $color-accent;
        font-weight: 800;
        border-bottom: 1px solid $color-primary;
        background-color: $color-light-200;
        padding: 1rem;
      }

      &__subtotal {
        font-size: 1.5rem;
        padding: 1rem;
        font-weight: 700;
      }
    }

    & .end-order-btn{
      padding: 1rem;
      width: max-content;
      font-weight: 700;
    }
  }
}

/* Medium: Desde 577px hasta 768px */
@media screen and (max-width: 768px) {
  .cart {
    & .title,
    & .cartbox {
      padding: 0 1rem;

      & .cartItem {
        column-gap: 0.7rem;
        grid-template-columns: min-content 1fr 1fr min-content;

        & img {
          display: none;
        }

        &__name {
          font-size: 1rem;
        }

        &__btnCounter {
          &--btn {
            width: auto;
            padding: 1.4rem;
          }
          &--number {
            padding: 0rem 1.5rem;
          }
        }
      }
    }
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .cart {
    & .cartbox {
      
      & .cartItem {
        padding: 0;
        border: none;
        column-gap: 0.5rem;
        padding: 0;

        &__btn {
          display: flex;
          justify-content: center;
          height: 40px;
          width: 40px;

          & i {
            font-size: 0.9rem;
          }
        }

        &__name {
          font-size: 0.8rem;
        }

        &__type {
          font-size: 0.7rem;
        }
       
        &__btnCounter {
  
          &--btn {
            padding: 0;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
          }
  
          &--number {
            padding: 0;
            font-size: 0.9rem;
            width: 40px;
          }
        }
        
        &__subtotal {
          font-size: 1.1rem;
        }
      }

      & .totalcart {
        align-items: center;
        
        &__title {
          padding: 0.5rem;
          font-size: 1.2rem;
        }
  
        &__subtotal {
          padding: 0.5rem;
          font-size: 1.2rem;
        }
      }
    }
  }

  
}
