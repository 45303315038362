@use '../../../../abstracts' as *;

.aboutme {
  margin-top: 10rem;

  &__head {
    padding: 0 1rem;

    & .self-present {
      color: $color-accent;
      font-size: 2rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 4rem;
    margin-top: 2rem;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;

    & .text {
      font-size: 1.4rem;
      color: #464646;
      line-height: 1.4;
    }

    & img {
      width: 20px;
      height: 20px;
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }

  &__right {
    // background-image: url('../../../../../assets/images/sobreMi/sobre-mi-lg.png');
    background-size: cover;
    background-position: bottom;
  }

  &__head {
    text-align: center;
    margin-bottom: 1rem;
  }

  // &__text {
  //   text-align: center;
  // }
}

/* Extra large: Desde 993px hasta 1200px */
@media screen and (max-width: 1200px) {
  .aboutme {
    &__head {
      & .self-present {
        font-size: 1.8rem;
      }
    }

    &__left {
      & .text {
        font-size: 1.3rem;
      }
    }
  }
}

/* Large: Desde 769px hasta 992px */
@media screen and (max-width: 992px) {
  .aboutme {
    &__head {
      & .self-present {
        font-size: 1.6rem;
      }
    }

    &__row {
      grid-template-columns: 1fr 1fr;
      background-size: cover;
      background-position: top;
      column-gap: 1rem;
    }

    &__right {
    }

    &__left {
      padding: 1.5rem;
      background-color: $color-primary-light;
      & .text {
        font-size: 1.2rem;
      }
    }
  }
}

/* Medium: Desde 577px hasta 768px */
@media screen and (max-width: 768px) {
  .aboutme {
    &__row {
      display: flex;
      flex-direction: column-reverse;
    }

    &__left {
      padding: 1.5rem;
    }

    &__right {
      display: block;
    }
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .aboutme {
    margin-top: 6rem;

    &__head {
      & .self-present {
        font-size: 1.4rem;
      }
    }

    &__row {
      grid-template-columns: 1fr;
      padding: 0 1rem;
    }

    &__left {
      padding: 1rem;
      & .text {
        font-size: 1rem;
      }
    }
  }
}
