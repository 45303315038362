.admin-event {
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(2, 1fr) min-content;
  column-gap: 2rem;
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.2);

  &__left {
    padding: 0.8rem;
    &-typeinfo {
      &-type {
        font-size: 0.7rem;
        font-weight: 700;
      }
      &-name {
        font-size: 1rem;
        font-weight: 700;
      }
      &-description {
        font-size: 0.9rem;
        margin-bottom: 10px;
      }
    }

    &-wheninfo {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1rem;
      background-color: rgb(193, 152, 78);
      padding: 0.8rem;

      &-datebox {
        align-items: center;
        display: flex;
        flex-direction: column;
        line-height: 0.95;

        &-namedy {
          font-size: 0.8rem;
          text-transform: uppercase;
        }
        &-numday {
          font-size: 2rem;
        }
        &-month {
          font-size: 0.9rem;
          font-weight: 700;
          text-transform: uppercase;
        }
        &-year {
          font-size: 1rem;
        }
      }

      &-timebox {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-timeboxgrid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 0.95;

        & .time {
          font-size: 1.5rem;
          font-weight: 600;
        }

        & .timeTo {
          font-size: 1.1rem;
          line-height: 0;
          padding: 0.6rem 0;
        }
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-place {
      font-size: 1rem;
    }
    &-address {
      font-size: 0.8rem;
    }

    &-pricebox {
      display: flex;
      flex-direction: column;
      background-color: rgb(193, 152, 78);
      width: fit-content;
      align-self: center;
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;

      &--pricetitle {
        font-size: 1rem;
        font-weight: 600;
      }

      &--price {
        font-size: 1rem;
      }

      &--price-alt {
        font-size: 1.5rem;
      }
    }

    &-organizer {
      font-size: 0.9rem;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    background-color: #dddddd;
    padding: 0 1rem;

    & button,
    a {
      width: fit-content;
      padding: 0.8rem 1rem;
    }
  }
}
