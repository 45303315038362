@use '../../../../abstracts' as *;

.education {
  margin-top: 10rem;

  &__up {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8rem;
    align-items: center;

    & h1 {
      font-size: 3rem;
      line-height: 1.4;
    }
  }

  &__down {
    display: grid;
    grid-template-columns: 500px 1fr;
    align-items: center;
    column-gap: 6rem;
    margin-top: 6rem;

    & .content-text {
      display: flex;
      flex-direction: column;

      & p {
        font-size: 1.4rem;
        line-height: 1.6;
      }

      & p span {
        font-size: 1.9rem;
        line-height: 1;
      }

      & a {
        font-size: 1.2rem;
        align-self: center;
      }
    }
  }

  & .image-box {
    position: relative;
    & .back {
      position: absolute;
      top: 50px;
      left: -50px;
      z-index: -1;
    }

    & .back-alt {
      position: absolute;
      top: -50px;
      left: 50px;
      z-index: -1;
    }

    & .front {
      z-index: 1;
    }
  }
}

/* Extra large: Desde 993px hasta 1200px */
@media screen and (max-width: 1200px) {
  .education {
    &__up {
      & h1 {
        font-size: 2.5rem;
        line-height: 1.3;
      }
    }

    &__down {
      grid-template-columns: 368px 1fr;
      column-gap: 4rem;
      margin-top: 4rem;

      & .content-text {
        & p {
          font-size: 1.2rem;
          line-height: 1.4;
        }

        & p span {
          font-size: 1.7rem;
        }

        & a {
          font-size: 1.1rem;
        }
      }
    }
  }
}

/* Large: Desde 769px hasta 992px */
@media screen and (max-width: 992px) {
  .education {
    &__up {
      column-gap: 3rem;

      & h1 {
        font-size: 2.2rem;
      }
    }

    &__down {
      grid-template-columns: 0.8fr 1fr;
      column-gap: 3.5rem;
      margin-top: 4rem;

      & .content-text {
        & p {
          font-size: 1rem;
          line-height: 1.4;
        }

        & p span {
          font-size: 1.4rem;
        }

        & a {
          font-size: 1rem;
        }
      }
    }

    & .image-box {
      & .back {
        top: 30px;
        left: -30px;
      }

      & .back-alt {
        top: -30px;
        left: 30px;
      }
    }
  }
}

/* Medium: Desde 577px hasta 768px */
@media screen and (max-width: 768px) {
  .education {
    margin-top: 6rem;

    &__up {
      column-gap: 2rem;

      & h1 {
        font-size: 1.8rem;
      }
    }

    &__down {
      grid-template-columns: 0.5fr 1fr;
      margin-top: 3rem;

      & .content-text {
        & p {
          font-size: 0.9rem;
          line-height: 1.4;
        }

        & p span {
          font-size: 1.2rem;
        }

        & a {
          font-size: 0.9rem;
        }
      }
    }
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .education {
    margin-top: 5rem;

    & .back {
      display: none;
    }

    &__up {
      grid-template-columns: 1fr;

      & h1 {
        padding-bottom: 1rem;
      }
    }

    &__down {
      grid-template-columns: 1fr;
      margin-top: 0rem;

      & .image-box {
        display: none;
      }
    }

    & .content-text {
      padding: 1rem;
    }
  }
}
