* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.row,
.col {
  margin: 0;
  padding: 0;

  & > * {
    margin: 0;
    padding: 0;
  }
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  line-height: inherit;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #ffffff;
}

.nav-link {
  padding: 0;
}

button {
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;

  &:focus {
    outline-color: transparent;
  }
}

img {
  max-width: 100%;
  height: auto;
}
