@use '../abstracts' as *;

.author {
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 1rem;

  & .paragraph {
    @include font-size($text-link-alt-font-size);
    text-align: center;
    margin-top: 1rem;
  }
  & .image {
    width: 150px;
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    max-width: 100%;
    border-radius: 10rem;
  }
}
