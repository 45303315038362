@use '../abstracts' as *;
// COMPONENT
.hero {
  background-color: $color-primary-light;
  & .content {
  }

  & .data {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  & .image {
  }
}
