.order-details {
  margin-bottom: 2rem;

  &__data {
    margin-top: 1rem;
    padding: 0 1rem 4rem 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr min-content;
    row-gap: 1rem;
    column-gap: 1rem;

    & > div {
      background-color: #ffffff;
      padding: 1.2rem;
    }
  }

  &__client {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  &__order {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  &__products {
    grid-column: 1 / -1;
    grid-row: 2 / -1;
  }
}
