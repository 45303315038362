.shop {
  margin-top: 4rem;
  margin-bottom: 4rem;

  & .title {
    margin-bottom: 2rem;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
  }
}

/* Large: Desde 769px hasta 992px */
@media screen and (max-width: 992px) {
  .shop {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .shop {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}