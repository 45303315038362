.popupcampaign {
  padding: 2rem;
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  position: absolute;
  overflow: auto;
  outline: none;
  width: 600px;
  background-image: url(../../assets/images/popup/fondo.png);
  box-shadow: 9px 6px 17px rgba(0, 0, 0, 0.1), 8px 11px 10px rgba(0, 0, 0, 0.1);
}

.popupcampaign__infobox {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1;
} 

.popupcampaign__infobox button {
  align-self: end;
  transform: translateY(50%);
}

.popupcampaign__infobox button i {
    font-size: 2rem;
    color: #d11c1c;
    cursor: pointer;
}

.popupcampaign__infobox img {
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
  transform: translateY(-50%);
}

.popupcampaign__infobox--content {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 2rem 2rem 2rem!important;
}

.popupcampaign__infobox h1 {
  font-weight: 600;
  color: #db793c;
  text-align: center;
}

.popupcampaign__infobox h2 {
  font-size: 3rem;
  font-family: var(--prime-font-family);
  color: #7b0f0f;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.popupcampaign__infobox h3 {
  font-size: 2rem;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
}

.popupcampaign__infobox h4 {
  font-family: var(--prime-font-family);
  font-size: 1.2rem;
  color: rgb(0, 0, 0);
  margin-bottom: 1rem;
}

.popupcampaign__infobox h5 {
  font-size: 0.9rem;
  line-height: 1.3;
  text-align: center;
  margin-top: 1.5rem;
}

.popupcampaign__infobox p {
  font-size: 1rem;
  color: rgb(14, 14, 14);
  line-height: 1.5;
  text-align: center;
}

.popupcampaign__infobox form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.popupcampaign__infobox form div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
}

.popupcampaign__infobox form input {
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 1px solid #7b0f0f;
  border-radius: 5px;
}

.popupcampaign__infobox form .check {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
}

.popupcampaign__infobox form .check input {
  width: auto;
  padding: 0.5rem;
  margin: 0 0.5rem 0 0;
}

.popupcampaign__infobox .popup-btn {
  background-color: #7b0f0f;
  color: white;
  transition: transform ease 0.3s;
  align-self: center;
}

.popupcampaign__alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #7b0f0f;
  color: white;
  border-radius: 5px;
  margin-top: 1rem;
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .popupcampaign__infobox div {
    border-radius: 0;
  }
}

/* Extra-Small: Desde 0px hasta 350px */
@media screen and (max-width: 350px) {
  .popupcampaign__infobox img {
    width: 70px;
  }

  .popupcampaign__infobox--content {
    padding: 0 1rem 1rem 1rem!important;
  } 

  .popupcampaign__infobox--content h1 {
    font-size: 1.5rem;
  }

  .popupcampaign__infobox--content h5 {
    margin-top: 1rem;
  }

  .popupcampaign__infobox--content p {
    font-size: 0.8rem;
    margin-top: 1rem;
  }

  .popupcampaign__infobox--content .xsmall {
    font-size: 0.6rem;
    margin-top: 0;
  }
}
