@use '../abstracts' as *;

.proposal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: min-content min-content;
  align-items: center;
  row-gap: 3rem;
  column-gap: 4rem;
  margin-bottom: 8rem;

  &__name {
    grid-column: 1 / span 2;
    grid-row: 1;
    align-self: end;
    & h1 {
      font-weight: 200;
      line-height: 1.3;
      font-size: 2.4rem;
    }
  }

  &__image {
    grid-column: 3 / span 2;
    grid-row: 1;
    position: relative;

    & .front {
      z-index: 1;
    }

    & .back {
      position: absolute;
      top: 40px;
      left: 125px;
      z-index: -1;
    }
  }

  &__data {
    display: grid;
    grid-template-rows: repeat(3, min-content);
    grid-column: 1 / span 2;
    grid-row: 2;
    align-content: center;
    row-gap: 0.5rem;

    & div {
      display: grid;
      grid-template-columns: min-content 1fr;
      grid-template-rows: max-content max-content;
      align-items: center;
      column-gap: 2rem;
      background-color: $color-accent;
      padding: 0.5rem 1rem;
    }

    & i {
      grid-column: 1;
      grid-row: 1;
      line-height: 0;
      justify-self: center;
      font-size: 2rem;
      color: $color-light-100;
      align-self: end;
    }

    &-item {
      grid-column: 1;
      grid-row: 2;
      font-size: 1rem;
      text-align: center;
      align-self: start;
      color: #fbfbfb;
    }

    &-value {
      grid-column: 2;
      grid-row: 1 / span 2;
      font-size: 1.4rem;
      color: #fbfbfb;
    }
  }

  &__description {
    grid-column: 3 / span 2;
    grid-row: 2;
  }
}

/* Large: Desde 769px hasta 992px */
@media screen and (max-width: 992px) {
  .proposal {
    &__image {
      & .back {
        left: 0;
      }
    }

    &__data {
      & i {
        font-size: 1.8rem;
      }

      &-item {
        font-size: 0.9rem;
      }

      &-value {
        font-size: 1.2rem;
      }
    }
  }
}

/* Medium: Desde 577px hasta 768px */
@media screen and (max-width: 768px) {
  .proposal {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, min-content);
    margin-bottom: 3rem;
    row-gap: 1rem;
    column-gap: 0;

    &__name {
      grid-column: 1;
      grid-row: 1;
    }

    &__image {
      grid-column: 1;
      grid-row: 2;
      display: flex;
      justify-content: center;

      & .back {
        display: none;
      }
    }

    &__data {
      grid-column: 1;
      grid-row: 4;
    }

    &__description {
      grid-column: 1;
      grid-row: 3;
    }
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .proposal {
    &__data {
      & i {
        font-size: 1.5rem;
      }

      &-item {
        font-size: 0.9rem;
      }

      &-value {
        font-size: 1.1rem;
      }
    }
  }
}

/* Extra-Small: Desde 0px hasta 350px */
@media screen and (max-width: 350px) {
}
