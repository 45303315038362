@use '../../../abstracts' as *;

.type {
  margin-top: 2rem;

  &__row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 4rem;
    column-gap: 2rem;

    & > button {
      flex-shrink: initial;
    }
  }

  & button {
    color: $color-dark;
    text-transform: uppercase;

    &:hover {
      background-color: $color-accent !important;
      color: $color-light-100 !important;
    }
  }

  & button:last-child {
    border-right: none;
  }
}

/* Medium: Desde 577px hasta 768px */
@media screen and (max-width: 768px) {
  .type {
    &__row {
      column-gap: 1rem;
    }
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .type {
    &__row {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 1rem;
      padding: 1rem;
    }
  }
}
