@use '../../../abstracts' as *;

.nuria {
  max-width: 760px;
  margin: 12rem auto 8rem auto;

  &__img {
    background-image: url(../../../../assets/images/agenda/nuria.png);
    height: 180px;
    max-width: 160px;
    margin: 0 auto;
    border-radius: 50%;
  }

  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  text-align: center;
  & .text {
    font-size: 1rem;
  }

  & .sign {
    font-size: 0.9rem;

    & span {
      font-family: var(--prime-font-family);
      font-size: 1.4rem;
    }
  }

  & a {
    width: fit-content;
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .nuria {
    &__row {
      margin: 0 1rem;
    }
  }
}
