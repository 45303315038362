@use '../../../abstracts' as *;

.admin {
  display: flex;
  margin-bottom: 4rem;

  & .admin-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  & .admin-section {
    height: 100vh;

    & .title {
      padding: 2rem 1.5rem;
      background-color: $color-accent;
      color: $color-light-100;
    }

    & .row-content {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding: 2rem 0.8rem;
      background-color: rgb(245 245 245);
    }

    // & .order {
    //   background-color: #ffffff;
    //   box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px 1px;
    //   border-radius: 10px;
    //   padding: 1rem;
    //   display: flex;
    //   flex-direction: row;
    //   column-gap: 2rem;
    //   justify-content: space-between;

    //   & .left {
    //     display: flex;
    //     flex-direction: column;
    //     row-gap: 1rem;

    //     & .admin-info {
    //       flex-grow: 1;
    //       display: flex;
    //       flex-direction: column;
    //       row-gap: 0.3rem;

    //       & .name {
    //         font-size: 1rem;
    //         font-weight: 700;
    //       }

    //       & .id {
    //         font-size: 0.9rem;
    //         margin-left: 0.5rem;
    //       }

    //       & .method {
    //         display: flex;
    //         flex-direction: row;
    //         font-size: 1rem;
    //         margin-left: 0.5rem;
    //       }

    //       & .total {
    //         font-size: 1.2rem;
    //         font-weight: 800;
    //         margin-left: 0.5rem;
    //       }
    //     }
    //   }

    //   & .center {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-evenly;

    //     & .admin-date {
    //       font-size: 0.9rem;
    //       color: rgb(175, 175, 175);
    //       text-align: left;
    //     }
    //   }

    //   & .right {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-evenly;
    //     row-gap: 0.5rem;

    //     & a {
    //       width: fit-content;
    //       padding: 0 0.5rem;
    //     }

    //     & button {
    //       padding: 0 0.5rem;
    //     }

    //     & .watch {
    //       display: grid;
    //       grid-template-columns: repeat(2, min-content) 1fr;
    //       column-gap: 0.5rem;

    //       // & a {
    //       //   width: fit-content;
    //       //   padding: 0 0.5rem;
    //       // }
    //     }
    //   }
    // }
  }
}
