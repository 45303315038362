@use '../../../../abstracts' as *;

.engagesecond {
  margin-top: 10rem;
  margin-bottom: 10rem;

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
    row-gap: 0rem;
    padding: 0 1rem;
  }

  &__header h1,
  &__text p {
    color: $color-dark;
  }

  &__header {
    grid-column: 1 / -1;
    grid-row: 1 / 1;
  }

  &__text {
    grid-column: 2 / -1;
    grid-row: 2 / 2;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;

    & .phrase {
      font-size: 1.8rem;
      grid-column: 2;
      text-align: right;
      margin: 0;

      & span {
        font-family: var(--prime-font-family);
        font-size: 2.7rem;
        line-height: 0.9;
      }
    }
  }

  &__imageBox {
    grid-column: 1 / 2;
    grid-row: 2 / -1;
    margin-top: 2rem;
    & img {
      max-width: 75%;
    }
  }
}

/* Extra large: Desde 993px hasta 1200px */
@media screen and (max-width: 1200px) {
  .engagesecond {
    &__text {
      & .phrase {
        font-size: 1.5rem;
        & span {
          font-size: 2.4rem;
        }
      }
    }
  }
}

/* Large: Desde 769px hasta 992px */
@media screen and (max-width: 992px) {
  .engagesecond {
    &__text {
      & .phrase {
        font-size: 1.3rem;

        & span {
          font-size: 1.9rem;
        }
      }
    }
  }
}

/* Medium: Desde 577px hasta 768px */
@media screen and (max-width: 768px) {
  .engagesecond {
    &__text {
      & .phrase {
        font-size: 1rem;

        & span {
          font-size: 1.6rem;
        }
      }
    }
  }
}

/* Small: Desde 351px hasta 576px */
@media screen and (max-width: 576px) {
  .engagesecond {
    margin-top: 6rem;
    margin-bottom: 6rem;

    &__row {
      grid-template-columns: 1fr;
      grid-template-rows: min-content 1fr min-content;
    }

    &__header {
      grid-column: 1 / -1;
      grid-row: 1 / 1;
    }

    &__text {
      grid-column: 1 / -1;
      grid-row: 3 / -1;
      align-self: flex-end;
      margin-top: 2rem;

      & .phrase {
        text-align: center;
      }
    }

    &__imageBox {
      grid-row: 2 / 2;
      display: flex;
      justify-content: center;
    }
  }
}
