// MIXINS
@use './breakpoints' as *;

// FONT-SIZE
@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    } @else {
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (max-width: $fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }
  }
}
