@use '../../abstracts' as *;

/******* ACCOUNT LAYOUT ******/
.main-account {
  min-height: 100vh;
  min-height: 100dvh;
  display: grid;
}

.account {
  display: flex;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    width: 400px;
    padding: 3rem;
  }

  &__subtitle {
    color: $color-primary;
  }

  &__image-box {
    position: relative;
    width: calc(100vw - 400px);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(225deg, #e91e63, #03a9f4);
      z-index: 1;
      mix-blend-mode: screen;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .account {
    display: grid;
    grid-template-areas: 'head' 'main';
    grid-template-rows: min-content 1fr;

    &__content {
      grid-area: main;
      justify-self: center;
    }

    &__image-box {
      grid-area: head;
      width: 100%;
      height: 100px;
    }
  }
}

/* For normal devices */
@media screen and (max-width: 576px) {
  .account {
    &__content {
      width: 320px;
      padding: 3rem 0;
    }
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .account {
    &__content {
      width: auto;
      padding: 3rem 1rem;
    }
  }
}
